/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import "typeface-open-sans";
import React from "react";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby"
// import "./style.css";
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <Header siteTitle={''} />
      <div
        style={{
          margin: `0 0`,
          padding: 0,
          maxWidth: '100%'
        }}
      >
        <main>{children}</main>
        {/* <footer style={{
          marginTop: `0`,
          textAlign: 'center',
          marginBottom: '3em'
        }}>
          Parminder Singh © {new Date().getFullYear()}, Developed By
          {` Struckhigh `}
        </footer> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
